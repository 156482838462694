import { Box, Typography } from "@mui/material";
import Loader from "../../../components/Loader";

function WhyShutter({ data, lang }) {
  if (!data) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        backgroundImage:
          "linear-gradient(to right, #310169,#38005A,#48006A,#680080,#67398D)",
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "3em",
        padding: "3em",
        flexDirection: lang === "en" ? "row" : "row-reverse",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2em",
          backgroundImage: {
            xs: "none",
            md: `url(${data?.map((item) => item.image)})`,
          },
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: lang === "en" ? "right" : "left",
          alignItems: lang === "en" ? "start" : "end",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "CairoBold",
            fontSize: { xs: "32px", sm: "36px", md: "42px", lg: "48px" },
            direction: lang === "en" ? "ltr" : "rtl",
          }}
        >
          {data?.map((item) => item.translations[0].translatedValue)}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            width: { md: "50%", lg: "80%" },
            direction: lang === "en" ? "ltr" : "rtl",
            display: "flex",
            justifyContent: lang === "en" ? "start" : "end",
          }}
        >
          {data?.map((item) => item.translations[1].translatedValue)}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={data?.map((item) => item.image)}
            alt=""
            style={{ width: "300px" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default WhyShutter;
