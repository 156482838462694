import { Box, Divider, Typography } from "@mui/material";

export default function AboutUs() {
  return (
    <Box
      sx={{
        bgcolor: "#0E060E",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src="/images/aboutUsBg2.png"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "550px",
          zIndex: 1,
          width: "500px",
        }}
        alt=""
      />

      <img
        src="/images/aboutUsBg.png"
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "550px",
          zIndex: 2,
        }}
        alt=""
      />

      <img
        src="/images/aboutUsEllipse.png"
        alt=""
        style={{ position: "absolute", right: 0, top: 0 }}
      />

      <Box sx={{ p: "2em" }}>
        <Typography
          variant="h2"
          sx={{
            fontFamily: "LilyRegular",
            textAlign: "center",
            color: "#FFF",
          }}
        >
          About Us
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            gap: "1em",
            p: "0 0 0 4em",
            position: "relative",
          }}
        >
          <img src="/images/aboutUs.png" alt="" style={{ zIndex: 1 }} />

          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.35)",
              borderRadius: "10px",
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10em",
                p: "2em",
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{ color: "#FFF", fontFamily: "Itim" }}
                >
                  2K+
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#FFF", fontFamily: "Itim" }}
                >
                  Event
                </Typography>
              </Box>

              <img src="/images/aboutUs1.png" alt="" />
            </Box>

            <Divider
              component="li"
              sx={{
                borderImage:
                  "linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%) 1",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10em",
                p: "2em",
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{ color: "#FFF", fontFamily: "Itim" }}
                >
                  12+
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#FFF", fontFamily: "Itim" }}
                >
                  Digital Products
                </Typography>
              </Box>

              <img src="/images/aboutUs2.png" alt="" />
            </Box>

            <Divider
              component="li"
              sx={{
                borderImage:
                  "linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%) 1",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10em",
                p: "2em",
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{ color: "#FFF", fontFamily: "Itim" }}
                >
                  95%
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#FFF", fontFamily: "Itim" }}
                >
                  Customer Satisfaction Rate
                </Typography>
              </Box>

              <img src="/images/aboutUs3.png" alt="" />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          p: "2em",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "5em",
        }}
      >
        <Box
          sx={{
            position: "relative",
            background:
              "linear-gradient(180deg, #EAEAEA 0%, #000000 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            p: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
            mt: "-5em",
          }}
        >
          <Box
            sx={{
              position: "relative",
              background:
                "linear-gradient(180deg, #53234B 15.45%, #0E060E 100%)",
              width: "200px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              zIndex: 3,
            }}
          >
            <Typography
              variant="body1"
              sx={{ maxWidth: "100%", textAlign: "center", color: "#FFF" }}
            >
              Shutter Studio has years of experience in running photo booth
              related businesses.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            background:
              "linear-gradient(180deg, #EAEAEA 0%, #000000 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            p: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
          }}
        >
          <Box
            sx={{
              position: "relative",
              background:
                "linear-gradient(180deg, #53234B 15.45%, #0E060E 100%)",
              width: "200px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              zIndex: 3,
            }}
          >
            <Typography
              variant="body1"
              sx={{ maxWidth: "100%", textAlign: "center", color: "#FFF" }}
            >
              Shutter Studio has years of experience in running photo booth
              related businesses.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            background:
              "linear-gradient(180deg, #EAEAEA 0%, #000000 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            p: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 3,
            mt: "-5em",
          }}
        >
          <Box
            sx={{
              position: "relative",
              background:
                "linear-gradient(180deg, #53234B 15.45%, #0E060E 100%)",
              width: "200px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              zIndex: 3,
            }}
          >
            <Typography
              variant="body1"
              sx={{ maxWidth: "100%", textAlign: "center", color: "#FFF" }}
            >
              Shutter Studio has years of experience in running photo booth
              related businesses.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
