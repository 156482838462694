import Title from "./components/TopBanner";
import Services from "./components/Services";
import WhyShutter from "./components/BottomBanner";
import Partners from "./components/Partners";
import MyAPI from "../../components/API";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Events from "./components/Events";
import Activisions from "./components/Activisions";
import AboutUs from "./components/AboutUs";

export default function Home() {
  const [data, setData] = useState(null);
  const { lang } = useParams();
  return (
    <Box sx={{ overflow: "hidden" }}>
      <MyAPI endpoint={"homepage"} setData={setData} />
      <Title data={data?.staticPages?.title} lang={lang} />
      <AboutUs />
      <Services lang={lang} />
      <Events lang={lang} />
      <Activisions lang={lang} />
      <Partners data={data?.sliders?.partners} />
    </Box>
  );
}
