import {
  Alert,
  Box,
  Fade,
  ImageList,
  ImageListItem,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QuotationModal from "../../../components/QuotationModal";
import HomeHeaderComponent from "./../../../components/HeaderBtns";

const staticData = [
  {
    headerBg: "/images/serviceH1.png",
    headerTitle: "Enjoy with our 360 photobooth.",
    bodyTitle: "360Phooth",
    serviceImg: "/images/360photobooth.png",
    serviceTitle: "360 Photobooth",
    serviceBody:
      "A 360 photobooth is an exciting and immersive addition to any event, offering a dynamic way to capture moments from every angle. This cutting-edge setup features a camera mounted on a rotating platform.",
  },

  {
    headerBg: "/images/serviceH2.png",
    headerTitle: "Enjoy with our photobooth",
    bodyTitle: "Photobooth",
    serviceImg: "/images/superPhotobooth.png",
    serviceTitle: "Super Photobooth",
    serviceBody:
      "A photobooth is a fun, interactive kiosk designed for capturing candid photos. Typically equipped with a camera, a backdrop, and sometimes props, it allows individuals or groups to take spontaneous pictures, often with instant prints. Modern photobooths can offer digital features, such as filters and social media sharing, enhancing the overall experience",
  },

  {
    headerBg: "/images/serviceH3.png",
    headerTitle: "Enjoy with our roboot",
    bodyTitle: "Roboot",
    serviceImg: "/images/roboot.png",
    serviceTitle: "Roboot",
    serviceBody:
      "The robot is interactive, utilizing voice recognition to facilitate seamless communication. It can navigate the venue autonomously, moving gracefully through crowds while avoiding obstacles. With its built-in camera, it can capture moments, offering photo opportunities for attendees.",
  },

  {
    headerBg: "/images/serviceH4.png",
    headerTitle: "Enjoy with our mini photobooth.",
    bodyTitle: "Mini booth",
    serviceImg: "/images/miniPhotobooth.png",
    serviceTitle: "Mini Photobooth",
    serviceBody:
      "A mini photobooth is a compact and portable setup designed for capturing fun, candid moments at events like parties, weddings, and corporate gatherings. Typically featuring a backdrop, a camera, and a screen for instant viewing, these booths allow guests to take selfies or group photos with customizable props and filters.",
  },

  {
    headerBg: "/images/serviceH5.png",
    headerTitle: "Enjoy with our chroma",
    bodyTitle: "Chroma",
    serviceImg: "/images/croma.png",
    serviceTitle: "Chroma",
    serviceBody:
      "A chroma Implementing technology like augmented reality or interactive installations that respond to attendee participation, often featuring colorful visuals that enhance engagement.",
  },
];

export default function SelectedService() {
  const [data, setData] = useState(null);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [openModal, setOpenModal] = useState(null);

  const { lang } = useParams();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/services/${1}`, {
        params: {
          language: lang,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  }, []);

  // function getYouTubeVideoId(url) {
  //   const videoIdPattern =
  //     /(?:\?v=|\/embed\/|\/watch\?v=|\/shorts\/)([a-zA-Z0-9_-]{11})/;
  //   const match = url.match(videoIdPattern);

  //   if (match && match[1]) {
  //     return match[1];
  //   }

  //   return null;
  // }

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [snackBar, setSnackBar] = useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarOpen = (Transition, isError) => () => {
    setSnackBar({
      open: true,
      Transition,
      isError,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <>
      {data ? (
        <>
          <Box sx={{ bgcolor: "#0E060E", position: "relative" }}>
            <img src={staticData[id]?.headerBg} style={{ width: "100%" }} />

            <Typography
              variant="h1"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontFamily: "BebasBold",
                color: "#FFF",
              }}
            >
              {staticData[id]?.headerTitle}
            </Typography>

            <HomeHeaderComponent open={handleOpenModal} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "2em",
              bgcolor: "#0E060E",
              position: "relative",
              zIndex: -1,
            }}
          >
            <img
              src="/images/serviceBg1.png"
              alt=""
              style={{
                position: "absolute",
                left: 0,
                height: "300%",
                zIndex: 0,
              }}
            />
            <Typography
              variant="h1"
              sx={{
                fontFamily: "LilyRegular",
                color: "#FFF",
              }}
            >
              {staticData[id]?.bodyTitle}
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: "#0E060E",
              display: "flex",
              maxWidth: "100%",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                width: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    "linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)",
                  zIndex: 1,
                }}
              />

              <img
                src={staticData[id]?.serviceImg}
                alt=""
                style={{
                  maxHeight: "60vh",
                  maxWidth: "100%",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  zIndex: 0,
                }}
              />
            </Box>

            <Box
              sx={{
                backgroundImage: `url(/images/servicesBg.png)`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                color: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "2em",
                gap: "1em",
                flex: 1,
                maxWidth: "100%",
              }}
            >
              <Typography
                variant="h3"
                sx={{ textAlign: "center", fontFamily: "Itim" }}
              >
                {staticData[id]?.serviceTitle}
              </Typography>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", fontFamily: "Itim" }}
              >
                {staticData[id]?.serviceBody}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "2em",
              bgcolor: "#0E060E",
              position: "relative",
              zIndex: -1,
            }}
          >
            <img
              src="/images/serviceBg2.png"
              alt=""
              style={{
                position: "absolute",
                right: 0,
                height: "300%",
                zIndex: 0,
              }}
            />
            <Typography
              variant="h1"
              sx={{
                fontFamily: "LilyRegular",
                color: "#FFF",
              }}
            >
              Gallery
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
              bgcolor: "#0E060E",
            }}
          >
            {data?.service?.activityImages.length > 0 ? (
              <ImageList variant="masonry" cols={3} gap={8} sx={{ p: 0, m: 0 }}>
                {data?.service?.activityImages?.map((photo, index) => (
                  <ImageListItem key={index}>
                    {isLoadingImages && <Loader />}
                    <img
                      src={photo.url}
                      alt={`Gallery ${index}`}
                      style={{
                        display: isLoadingImages ? "none" : "block",
                      }}
                      onLoad={() => setIsLoadingImages(false)}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              <Typography variant="h6" sx={{ fontWeight: 600, p: "2em" }}>
                No Data To Display
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <Loader />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "4em",
          bgcolor: "#0E060E",
          position: "relative",
          zIndex: -1,
        }}
      >
        <img
          src="/images/serviceBg3.png"
          alt=""
          style={{
            position: "absolute",
            right: 0,
            height: "500%",
            zIndex: 0,
          }}
        />
      </Box>
      {openModal === "quotation" && (
        <QuotationModal
          open={true}
          handleClose={handleCloseModal}
          onSuccess={handleSnackBarOpen(SlideTransition, false)}
          onError={handleSnackBarOpen(SlideTransition, true)}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        TransitionComponent={snackBar.Transition}
        key={snackBar.Transition.name}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.isError ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {snackBar.isError
            ? "We were unable to submit your form!"
            : "Form Submitted Successfully!"}
        </Alert>
      </Snackbar>
    </>
  );
}
