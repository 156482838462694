import React, { useState, useRef, useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Slider from "react-slick";
import { ArrowRightAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const data = [
  {
    id: 0,
    image: "/images/eventsSlider1.png",
    title: "Communities",
    body: "It allows users to easily create an event page with details like title, date, and location. Visitors can register directly through the platform, which integrates with payment systems to streamline revenue management.",
  },

  {
    id: 1,
    image: "/images/eventsSlider2.png",
    title: "Webinar",
    body: "Webinars are online sessions that provide educational or presentation content, allowing presenters and the audience to interact in real-time through features like Q&A and discussions.",
  },

  {
    id: 2,
    image: "/images/eventsSlider3.jpg",
    title: "3D Event",
    body: "Visual Events use visual content like presentations and videos to engage the audience and provide an interactive experience. These events are designed to captivate attendees through dynamic and visually appealing formats, making the content more memorable.",
  },
];

export default function Events({ lang }) {
  const [sliderSettings, setSliderSettings] = useState({});

  const { t } = useTranslation();
  const sliderRef = useRef();

  useEffect(() => {
    setSliderSettings({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipe: true,
      swipeToSlide: true,
      arrows: false,
      autoplay: true,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      let slidesToShow = 3;

      if (window.innerWidth < 960) {
        slidesToShow = 2;
      }

      if (window.innerWidth < 600) {
        slidesToShow = 1;
      }

      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow,
      }));
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        gap: "2em",
        padding: "3em",
        bgcolor: "#0E060E",
        position: "relative",
      }}
    >
      <img
        src="/images/sliderEllipse.png"
        style={{
          position: "absolute",
          top: "-5em",
          right: 0,
          zIndex: 1,
          width: "300px",
          transform: "rotate(180deg)",
        }}
        alt=""
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // flexDirection: { xs: "column", sm: "row" },
          // gap: { xs: "2em", sm: "0" },
          // direction: lang === "en" ? "ltr" : "rtl",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "LilyRegular",
            textAlign: "center",
            color: "#FFF",
          }}
        >
          Our Virtual Events
        </Typography>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            "> button": {
              width: "50px",
              height: "50px",
              bgcolor: "#D9D9D9",
              "& svg.MuiSvgIcon-root": {
                width: "100%",
              },
            },
            flexDirection: lang === "en" ? "row" : "row-reverse",
          }}
        >
          <IconButton onClick={previousSlide}>
            <ArrowRightAlt sx={{ transform: "rotate(180deg)" }} />
          </IconButton>
          <IconButton onClick={nextSlide}>
            <ArrowRightAlt />
          </IconButton>
        </Box> */}
      </Box>
      {data ? (
        <Slider ref={sliderRef} {...sliderSettings}>
          {data?.map((service, index) => (
            <Box key={index}>
              <Box
                component={Link}
                to={`events/${service.id}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "0.5em",
                  borderRadius: "10px",
                  cursor: "pointer",
                  transition: "0.3s all",
                  "&:hover": {
                    transform: "scale(0.95)",
                  },
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <img
                    src={service.image}
                    alt={`Slide ${index}`}
                    style={{
                      height: "300px",
                      borderRadius: "30px",
                    }}
                  />

                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundImage: `linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)`,
                      borderRadius: "30px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "start",
                      flexDirection: "column",
                      p: "1em",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ color: "#FFF", fontFamily: "Itim" }}
                    >
                      {service.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "#FFF", fontFamily: "Itim" }}
                    >
                      {service.body}
                    </Typography>

                    <Button
                      component={Link}
                      to={`events/${service.id}`}
                      variant="contained"
                      sx={{
                        fontFamily: "Itim",
                        color: "#FFF",
                        bgcolor: "#D9AD00",
                        mt: "1em",
                        borderRadius: "15px",
                      }}
                    >
                      More Details
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      ) : (
        <Loader />
      )}
    </Box>
  );
}
