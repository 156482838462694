import {
  Alert,
  Box,
  Button,
  Fade,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import QuotationModal from "../../../components/QuotationModal";
import HomeHeaderComponent from "./../../../components/HeaderBtns";

const staticData = [
  {
    headerBg: "/images/activisionH1.png",
    headerTitle: "Survey",
    bodyTitle: "Survey",
    serviceImg: "/images/activision1.png",
    serviceTitle: "Survey",
    serviceBody:
      "Surveys are tools designed to gather feedback from participants, offering insights that can enhance planning and execution.",
    previewImg: "/images/actPreview1.png",
    tabItems: [],
    hasQuestion: true,
    hasMetaverse: false,
    hasExperience: true,
  },

  {
    headerBg: "/images/activisionH2.png",
    headerTitle: "Ticket",
    bodyTitle: "Ticket",
    serviceImg: "/images/activision2.png",
    serviceTitle: "Ticket",
    serviceBody:
      "A comprehensive ticketing system that can be used to manage ticket sales for events and concerts both online and at the actual event location. The system aims to facilitate the process of purchasing and managing tickets in an organized and efficient manner.",
    previewImg: "/images/actPreview2.png",
    tabItems: [
      {
        img: "/images/camera.png",
        title: "Transferring tickets",
      },

      {
        img: "/images/gravity.png",
        title: "Printing QR Code ",
      },

      {
        img: "/images/revolt.png",
        title: "Ability to export ",
      },

      {
        img: "/images/gif.png",
        title: "Easy User Interface",
      },

      {
        img: "/images/robot.png",
        title: "Email and SMS Notifications",
      },

      {
        img: "/images/infinity.png",
        title: "Reports and Analytics",
      },

      {
        img: "/images/camera.png",
        title: "Multi-session ,ticket management",
      },

      {
        img: "/images/gravity.png",
        title: "Electronic Payment Support",
      },

      {
        img: "/images/revolt.png",
        title: "On-site Point of Sale",
      },
    ],
    hasQuestion: false,
    hasMetaverse: true,
    hasExperience: true,
  },

  {
    headerBg: "/images/activisionH3.png",
    headerTitle: "Registration",
    bodyTitle: "Registration",
    serviceImg: "/images/activision3.png",
    serviceTitle: "Registration",
    serviceBody:
      "A comprehensive event registration system that can be used to manage the attendance registration process for various events. The system aims to simplify the registration process and collect attendance data in an organized manner.",
    previewImg: "/images/actPreview3.png",
    tabItems: [
      {
        img: "/images/camera.png",
        title: "Transferring tickets",
      },

      {
        img: "/images/gravity.png",
        title: "Printing QR Code ",
      },

      {
        img: "/images/revolt.png",
        title: "Ability to export ",
      },

      {
        img: "/images/gif.png",
        title: "Easy User Interface",
      },

      {
        img: "/images/robot.png",
        title: "Email and SMS Notifications",
      },

      {
        img: "/images/infinity.png",
        title: "Reports and Analytics",
      },

      {
        img: "/images/camera.png",
        title: "Multi-session ,ticket management",
      },

      {
        img: "/images/gravity.png",
        title: "Electronic Payment Support",
      },

      {
        img: "/images/revolt.png",
        title: "On-site Point of Sale",
      },
    ],
    hasQuestion: false,
    hasMetaverse: false,
    hasExperience: true,
  },

  {
    headerBg: "/images/activisionH4.png",
    headerTitle: "Quiz",
    bodyTitle: "Quiz",
    serviceImg: "/images/activision4.png",
    serviceTitle: "Quiz",
    serviceBody:
      "The quiz system is designed to assess users' preferences and knowledge through a series of multiple-choice questions. It provides instant feedback on the answers, showing whether the response was correct or incorrect and tracks the user's score.",
    previewImg: "/images/actPreview4.png",
    tabItems: [
      {
        img: "/images/camera.png",
        title: "Transferring tickets",
      },

      {
        img: "/images/gravity.png",
        title: "Printing QR Code ",
      },

      {
        img: "/images/revolt.png",
        title: "Ability to export ",
      },

      {
        img: "/images/gif.png",
        title: "Easy User Interface",
      },

      {
        img: "/images/robot.png",
        title: "Email and SMS Notifications",
      },

      {
        img: "/images/infinity.png",
        title: "Reports and Analytics",
      },

      {
        img: "/images/camera.png",
        title: "Multi-session ,ticket management",
      },

      {
        img: "/images/gravity.png",
        title: "Electronic Payment Support",
      },

      {
        img: "/images/revolt.png",
        title: "On-site Point of Sale",
      },
    ],
    hasQuestion: true,
    hasMetaverse: false,
    hasExperience: false,
  },

  {
    headerBg: "/images/activisionH5.png",
    headerTitle: "Feedback",
    bodyTitle: "Feedback",
    serviceImg: "/images/activision5.png",
    serviceTitle: "Feedback",
    serviceBody:
      "The feedback question system is designed to gather user opinions and satisfaction levels through various formats like rating scales, star ratings, and preference rankings. It helps organizations understand user experiences, measure satisfaction, and collect insights for service improvements. The system offers an intuitive interface for participants to quickly provide feedback, enabling data-driven decision-making.",
    previewImg: "/images/actPreview5.png",
    tabItems: [
      {
        img: "/images/camera.png",
        title: "Transferring tickets",
      },

      {
        img: "/images/gravity.png",
        title: "Printing QR Code ",
      },

      {
        img: "/images/revolt.png",
        title: "Ability to export ",
      },

      {
        img: "/images/gif.png",
        title: "Easy User Interface",
      },

      {
        img: "/images/robot.png",
        title: "Email and SMS Notifications",
      },

      {
        img: "/images/infinity.png",
        title: "Reports and Analytics",
      },

      {
        img: "/images/camera.png",
        title: "Multi-session ,ticket management",
      },

      {
        img: "/images/gravity.png",
        title: "Electronic Payment Support",
      },

      {
        img: "/images/revolt.png",
        title: "On-site Point of Sale",
      },
    ],
    hasQuestion: true,
    hasMetaverse: false,
    hasExperience: false,
  },

  {
    headerBg: "/images/activisionH6.png",
    headerTitle: "Assessment",
    bodyTitle: "Assessment",
    serviceImg: "/images/activision6.png",
    serviceTitle: "Assessment",
    serviceBody:
      "An assessment system that involves writing questions and providing classification results at the end is a tool used to measure knowledge or skills. It is commonly used in education, training, and recruitment.",
    previewImg: "/images/actPreview6.png",
    tabItems: [
      {
        img: "/images/camera.png",
        title: "Reusable questions",
      },

      {
        img: "/images/revolt.png",
        title: "Objective results",
      },

      {
        img: "/images/camera.png",
        title: " Online access",
      },

      {
        img: "/images/gravity.png",
        title: "Performance reports",
      },
    ],
    hasQuestion: true,
    hasMetaverse: false,
    hasExperience: false,
  },
];

export default function SelectedActivision() {
  const [openModal, setOpenModal] = useState(null);
  const { id } = useParams();

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [snackBar, setSnackBar] = useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarOpen = (Transition, isError) => () => {
    setSnackBar({
      open: true,
      Transition,
      isError,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <>
      <Box sx={{ bgcolor: "#0E060E", position: "relative" }}>
        <img src={staticData[id]?.headerBg} style={{ width: "100%" }} />

        <Typography
          variant="h1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontFamily: "BebasBold",
            color: "#FFF",
          }}
        >
          {staticData[id]?.headerTitle}
        </Typography>

        <HomeHeaderComponent open={handleOpenModal} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "2em",
          bgcolor: "#0E060E",
          position: "relative",
          zIndex: -1,
        }}
      >
        <img
          src="/images/serviceBg1.png"
          alt=""
          style={{
            position: "absolute",
            left: 0,
            height: "300%",
            zIndex: 0,
          }}
        />
        <Typography
          variant="h1"
          sx={{
            fontFamily: "LilyRegular",
            color: "#FFF",
          }}
        >
          {staticData[id]?.bodyTitle}
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: "#0E060E",
          display: "flex",
          maxWidth: "100%",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{ display: "flex", flex: 1, width: "100%", position: "relative" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)",
              zIndex: 1,
            }}
          />

          <img
            src={staticData[id]?.serviceImg}
            alt=""
            style={{
              maxHeight: "60vh",
              maxWidth: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center",
              zIndex: 0,
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundImage: `url(/images/servicesBg.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            color: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2em",
            gap: "1em",
            flex: 1,
            maxWidth: "100%",
          }}
        >
          <Typography
            variant="h3"
            sx={{ textAlign: "center", fontFamily: "Itim" }}
          >
            {staticData[id]?.serviceTitle}
          </Typography>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontFamily: "Itim" }}
          >
            {staticData[id]?.serviceBody}
          </Typography>
        </Box>
      </Box>

      {staticData[id]?.tabItems?.length > 0 && (
        <Box sx={{ bgcolor: "#0E060E", p: "2em 0" }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {staticData[id]?.tabItems?.map((item, index) => (
              <Tab
                key={index}
                sx={{ width: "100%", display: "flex" }}
                label={
                  <Box>
                    <Box
                      sx={{
                        bgcolor: "#FFFFFF3B",
                        p: "1em",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                      }}
                    >
                      <img src={item.img} alt="" style={{ width: "50px" }} />
                    </Box>

                    <Typography
                      variant="body1"
                      sx={{
                        color: "#FFFFFF",
                        textDecoration: "none",
                        mt: "1em",
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>
      )}

      <Box sx={{ bgcolor: "#0E060E", p: "4em" }}>
        <Typography
          variant="h1"
          sx={{
            fontFamily: "LilyRegular",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          Preview
        </Typography>

        <Box sx={{ position: "relative", mt: "2em" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)",
              zIndex: 1,
              borderRadius: "40px",
            }}
          />

          <img
            src={staticData[id].previewImg}
            alt=""
            style={{ width: "100%", zIndex: 0 }}
          />

          <img
            src="/images/playBtn.png"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
            }}
            alt=""
          />
        </Box>
      </Box>

      {staticData[id]?.hasMetaverse && (
        <Box sx={{ position: "relative", bgcolor: "#0E060E" }}>
          <img src="/images/metaverse.png" style={{ width: "100%" }} alt="" />

          <Box
            sx={{
              position: "absolute",
              left: "2em",
              bottom: "2em",
              width: "100%",
            }}
          >
            <Typography variant="h4" sx={{ fontFamily: "Itim", color: "#FFF" }}>
              You can now use metaverse easily!
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: "Itim",
                color: "#FFF",
                maxWidth: { xs: "100%", lg: "50%" },
              }}
            >
              Empowering Growth Through Shared Learning and Metaverse Services.
              Explore a 3D Virtual Space for Enhanced Communication and
              Interactive Experiences.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Itim",
                color: "#FFF",
                bgcolor: "#D9AD00",
                mt: "1em",
              }}
            >
              More Details
            </Button>
          </Box>
        </Box>
      )}

      {staticData[id]?.hasQuestion && (
        <Box sx={{ position: "relative", bgcolor: "#0E060E" }}>
          <img src="/images/questionBg.png" style={{ width: "100%" }} alt="" />

          <Box
            sx={{
              position: "absolute",
              left: "2em",
              bottom: "2em",
              width: "100%",
            }}
          >
            <Typography variant="h4" sx={{ fontFamily: "Itim", color: "#FFF" }}>
              You can easily add questions using the add button.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Itim",
                color: "#FFF",
                bgcolor: "#D9AD00",
                mt: "1em",
              }}
            >
              Create Now
            </Button>
          </Box>
        </Box>
      )}

      {staticData[id]?.hasExperience && (
        <Box
          sx={{
            bgcolor: "#0E060E",
            display: "flex",
            maxWidth: "100%",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <img
              src="/images/experience.png"
              alt=""
              style={{
                zIndex: 0,
              }}
            />
          </Box>

          <Box
            sx={{
              color: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              padding: "2em",
              gap: "1em",
              flex: 1,
              maxWidth: "100%",
            }}
          >
            <Typography variant="h3" sx={{ fontFamily: "Itim" }}>
              Empower your experience for 3 ways
            </Typography>
            <Typography variant="h6" sx={{ fontFamily: "Itim" }}>
              Manage your dashboard easily. Choose Your Design Mode According to
              Your Style. Strategic Business Insights Through Statistics.
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontFamily: "Itim",
                color: "#FFF",
                bgcolor: "#D9AD00",
                mt: "1em",
              }}
            >
              Create Now
            </Button>
          </Box>
        </Box>
      )}

      {openModal === "quotation" && (
        <QuotationModal
          open={true}
          handleClose={handleCloseModal}
          onSuccess={handleSnackBarOpen(SlideTransition, false)}
          onError={handleSnackBarOpen(SlideTransition, true)}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        TransitionComponent={snackBar.Transition}
        key={snackBar.Transition.name}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.isError ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {snackBar.isError
            ? "We were unable to submit your form!"
            : "Form Submitted Successfully!"}
        </Alert>
      </Snackbar>
    </>
  );
}
