import React, { useState, useRef, useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Slider from "react-slick";
import { ArrowRightAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const data = [
  {
    id: 0,
    image: "/images/activisionSlider1.png",
    title: "Survey",
    body: "Surveys are tools designed to gather feedback from participants, offering insights that can enhance planning and execution.",
  },

  {
    id: 1,
    image: "/images/activisionSlider2.png",
    title: "Ticket",
    body: "A comprehensive ticketing system that can be used to manage ticket sales for events and concerts both online and at the actual event location. The system aims to facilitate the process of purchasing and managing tickets in an organized and efficient manner.",
  },

  {
    id: 2,
    image: "/images/activisionSlider3.jpg",
    title: "Registration",
    body: "A comprehensive event registration system that can be used to manage the attendance registration process for various events. The system aims to simplify the registration process and collect attendance data in an organized manner.",
  },

  {
    id: 3,
    image: "/images/activisionSlider4.jpg",
    title: "Quiz",
    body: "The quiz system is designed to assess users` preferences and knowledge through a series of multiple-choice questions. It provides instant feedback on the answers, showing whether the response was correct or incorrect and tracks the user`s score.",
  },

  {
    id: 4,
    image: "/images/activisionSlider5.jpg",
    title: "Feedback",
    body: "The feedback question system is designed to gather user opinions and satisfaction levels through various formats like rating scales, star ratings, and preference rankings. It helps organizations understand user experiences.",
  },

  {
    id: 5,
    image: "/images/activisionSlider6.jpg",
    title: "Assessment",
    body: "An assessment system that involves writing questions and providing classification results at the end is a tool used to measure knowledge or skills. It is commonly used in education, training, and recruitment.",
  },
];

export default function Activisions({ lang }) {
  const [sliderSettings, setSliderSettings] = useState({});

  const { t } = useTranslation();
  const sliderRef = useRef();

  useEffect(() => {
    setSliderSettings({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipe: true,
      swipeToSlide: true,
      arrows: false,
      autoplay: true,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      let slidesToShow = 3;

      if (window.innerWidth < 960) {
        slidesToShow = 2;
      }

      if (window.innerWidth < 600) {
        slidesToShow = 1;
      }

      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow,
      }));
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        gap: "2em",
        padding: "3em",
        bgcolor: "#0E060E",
        position: "relative",
      }}
    >
      <img
        src="/images/sliderEllipse.png"
        style={{
          position: "absolute",
          top: "-5em",
          left: 0,
          zIndex: 1,
          width: "300px",
        }}
        alt=""
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // flexDirection: { xs: "column", sm: "row" },
          // gap: { xs: "2em", sm: "0" },
          // direction: lang === "en" ? "ltr" : "rtl",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "LilyRegular",
            textAlign: "center",
            color: "#FFF",
          }}
        >
          Our Digital Activation
        </Typography>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            "> button": {
              width: "50px",
              height: "50px",
              bgcolor: "#D9D9D9",
              "& svg.MuiSvgIcon-root": {
                width: "100%",
              },
            },
            flexDirection: lang === "en" ? "row" : "row-reverse",
          }}
        >
          <IconButton onClick={previousSlide}>
            <ArrowRightAlt sx={{ transform: "rotate(180deg)" }} />
          </IconButton>
          <IconButton onClick={nextSlide}>
            <ArrowRightAlt />
          </IconButton>
        </Box> */}
      </Box>
      {data ? (
        <Slider ref={sliderRef} {...sliderSettings}>
          {data?.map((service, index) => (
            <Box key={index}>
              <Box
                component={Link}
                to={`activision/${service.id}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "0.5em",
                  borderRadius: "10px",
                  cursor: "pointer",
                  transition: "0.3s all",
                  "&:hover": {
                    transform: "scale(0.95)",
                  },
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <img
                    src={service.image}
                    alt={`Slide ${index}`}
                    style={{
                      height: "300px",
                      width: "600px",
                      borderRadius: "30px",
                    }}
                  />

                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundImage: `linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)`,
                      borderRadius: "30px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "start",
                      flexDirection: "column",
                      p: "1em",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ color: "#FFF", fontFamily: "Itim" }}
                    >
                      {service.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "#FFF", fontFamily: "Itim" }}
                    >
                      {service.body}
                    </Typography>

                    <Button
                      component={Link}
                      to={`activision/${service.id}`}
                      variant="contained"
                      sx={{
                        fontFamily: "Itim",
                        color: "#FFF",
                        bgcolor: "#D9AD00",
                        mt: "1em",
                        borderRadius: "15px",
                      }}
                    >
                      More Details
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      ) : (
        <Loader />
      )}
    </Box>
  );
}
