import {
  Alert,
  Box,
  Button,
  Fade,
  Grid,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import MyAPI from "../../components/API";
import Loader from "./../../components/Loader";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HomeHeaderComponent from "../../components/HeaderBtns";
import QuotationModal from "../../components/QuotationModal";

export default function Addons() {
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const { lang } = useParams();
  const { t } = useTranslation();

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [snackBar, setSnackBar] = useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarOpen = (Transition, isError) => () => {
    setSnackBar({
      open: true,
      Transition,
      isError,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };
  return (
    <>
      <MyAPI endpoint={"addons"} setData={setData} />
      <Box
        sx={{ bgcolor: "#0E060E", position: "relative", overflow: "hidden" }}
      >
        <img src="/images/addonsBg.png" style={{ width: "100%" }} alt="" />

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage:
              "linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)",
            zIndex: 1,
          }}
        />

        <Typography
          variant="h1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontFamily: "BebasBold",
            color: "#FFF",
            zIndex: 2,
          }}
        >
          Add ons
        </Typography>

        <HomeHeaderComponent open={handleOpenModal} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "2em",
          bgcolor: "#0E060E",
          position: "relative",
          zIndex: -1,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "LilyRegular",
            color: "#FFF",
          }}
        >
          Add Ons
        </Typography>
      </Box>
      {/* <Box
        sx={{
          bgcolor: "#D9D9D9",
          padding: "2em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data ? (
          <Typography
            variant="body1"
            sx={{
              background:
                "linear-gradient(to right, #CD51CA 0%, #5360E2 100%, #3065E9 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              width: "70%",
              fontFamily: "CairoSemiBold",
              fontSize: {
                xs: "20px",
                sm: "22px",
                md: "24px",
                lg: "28px",
                xl: "32px",
              },
              direction: lang === "en" ? "ltr" : "rtl",
            }}
          >
            {data?.staticPages?.topBanner?.map(
              (item) => item.translations[0]?.translatedValue
            )}
          </Typography>
        ) : (
          <Loader />
        )}
      </Box> */}
      {data ? (
        <Grid container>
          {data?.addons?.map((addon, index) => (
            <Grid item xs={12} key={index} sx={{ maxWidth: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: [index % 2 === 0 ? "row" : "row-reverse"],
                  },
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <img src={addon.image} alt="" style={{ width: "100%" }} />

                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        "linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)",
                      zIndex: 1,
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1em",
                    flex: 1,
                    justifyContent: "center",
                    backgroundImage: `url(/images/servicesBg.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      background:
                        "linear-gradient(to right, #CD51CA 0%, #5360E2 100%, #3065E9 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textAlign: "center",
                      fontFamily: "Itim",
                    }}
                  >
                    {
                      addon.translations?.find(
                        (item) => item.fieldName === "title"
                      )?.translatedValue
                    }
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Itim",
                      maxWidth: "75%",
                    }}
                  >
                    {
                      addon.translations?.find(
                        (item) => item.fieldName === "description"
                      )?.translatedValue
                    }
                  </Typography>
                  {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      component={Link}
                      to={`/${lang}/whatisnew`}
                      sx={{
                        background:
                          "linear-gradient(to right, #CD51CA 0%, #5360E2 100%, #3065E9 100%)",
                        color: "white",
                        borderRadius: "10px",
                        width: {
                          xs: "80%",
                          sm: "70%",
                          md: "60%",
                          lg: "50%",
                          xl: "40%",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "CairoMedium",
                          fontSize: {
                            xs: "20px",
                            md: "24px",
                          },
                          whiteSpace: "nowrap",
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                      >
                        {t("see it in action")}
                      </Typography>
                    </Button>
                  </Box> */}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Loader />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "4em",
          bgcolor: "#0E060E",
          position: "relative",
          zIndex: -1,
        }}
      >
        <img
          src="/images/serviceBg3.png"
          alt=""
          style={{
            position: "absolute",
            right: 0,
            height: "500%",
            zIndex: 0,
          }}
        />
      </Box>

      {openModal === "quotation" && (
        <QuotationModal
          open={true}
          handleClose={handleCloseModal}
          onSuccess={handleSnackBarOpen(SlideTransition, false)}
          onError={handleSnackBarOpen(SlideTransition, true)}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        TransitionComponent={snackBar.Transition}
        key={snackBar.Transition.name}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.isError ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {snackBar.isError
            ? "We were unable to submit your form!"
            : "Form Submitted Successfully!"}
        </Alert>
      </Snackbar>
    </>
  );
}
