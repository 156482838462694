import { Alert, Box, Fade, Slide, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import HomeHeaderComponent from "../../../components/HeaderBtns";
import QuotationModal from "../../../components/QuotationModal";

function Title() {
  const [openModal, setOpenModal] = useState(null);

  const data = ["/images/home1.png", "/images/home2.png", "/images/home3.png"];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClick = () => {
    // Increment the image index and cycle back to 0 if it reaches the end
    setCurrentImageIndex((currentImageIndex + 1) % data.length);
  };

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [snackBar, setSnackBar] = useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarOpen = (Transition, isError) => () => {
    setSnackBar({
      open: true,
      Transition,
      isError,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <Box
      sx={{ position: "relative", bgcolor: "#0E060E" }}
      onClick={handleClick}
    >
      <img src="/images/homeBg.png" style={{ width: "100%" }} alt="" />

      <img
        src={data[currentImageIndex]}
        style={{
          maxHeight: "50%",
          position: "absolute",
          left: "50%",
          bottom: 0,
          transform: "translate(-50%)",
        }}
        alt=""
      />

      <Box sx={{ position: "absolute", top: "40%", left: "2em" }}>
        <Typography variant="h1" sx={{ color: "#FFF", fontFamily: "Bebas" }}>
          Capture the Moment.
        </Typography>
        <Typography variant="h1" sx={{ color: "#FFF", fontFamily: "Bebas" }}>
          Relive the fun.
        </Typography>
        <Typography variant="h1" sx={{ color: "#FFF", fontFamily: "Bebas" }}>
          Cherish the Memories.
        </Typography>
      </Box>

      <HomeHeaderComponent open={handleOpenModal} />

      {openModal === "quotation" && (
        <QuotationModal
          open={true}
          handleClose={handleCloseModal}
          onSuccess={handleSnackBarOpen(SlideTransition, false)}
          onError={handleSnackBarOpen(SlideTransition, true)}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        TransitionComponent={snackBar.Transition}
        key={snackBar.Transition.name}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.isError ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {snackBar.isError
            ? "We were unable to submit your form!"
            : "Form Submitted Successfully!"}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Title;
