import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const selectedLanguage = useLocation().pathname.split("/")[1];

  const [isScrolled, setIsScrolled] = useState(false);

  const open = Boolean(anchorEl);
  const location = useLocation();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "#FFFFFF",
          },
          scrollButtons: {
            "& .MuiSvgIcon-root": {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
  });

  const navItems = [
    { name: t("home"), link: `/${selectedLanguage}` },
    { name: t("services"), link: `/${selectedLanguage}/services` },
    { name: t("projects"), link: `/${selectedLanguage}/projects` },
    { name: t("addons"), link: `/${selectedLanguage}/addons` },
    { name: t("what’s new"), link: `/${selectedLanguage}/whatisnew` },
  ];

  useEffect(() => {
    const selectedIndex = navItems.findIndex(
      (item) => item.link === location.pathname
    );

    if (selectedIndex !== -1) {
      setValue(selectedIndex);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor:
            location.pathname.includes("/services") &&
            !location.pathname.match(/\/services\/\d+/)
              ? "#0E060E"
              : location.pathname.includes("projects")
              ? "#0E060E"
              : location.pathname.includes("whatisnew")
              ? "#0E060E"
              : isScrolled
              ? "#0E060E"
              : "transparent",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position:
            location.pathname.includes("/services") &&
            !location.pathname.match(/\/services\/\d+/)
              ? "sticky"
              : location.pathname.includes("projects")
              ? "sticky"
              : location.pathname.includes("whatisnew")
              ? "sticky"
              : "fixed",
          top: "0",
          right: "0",
          left: "0",
          zIndex: 9,
          gap: "1em",
          transition: "background-color 0.3s ease-in-out",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "120px",
            height: "60px",
            padding: "1em",
          }}
        >
          <img
            src="/images/shutterLogo.png"
            alt=""
            style={{
              width: "100%",
              zIndex: "1",
            }}
          />
        </Box>
        <ThemeProvider theme={theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              "& .MuiTabs-scrollableX .MuiTabs-flexContainer": {
                gap: { xs: "0.5em", md: "1em", xl: "2em" },
              },
              display: { xs: "none", sm: "flex" },
              direction: selectedLanguage === "en" ? "ltr" : "rtl",
            }}
          >
            {navItems?.map((item, index) => (
              <Tab
                key={index}
                component={Link}
                to={item.link}
                label={
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        md: "24px",
                      },
                      color: "#FFFFFF",
                      textDecoration: "none",
                    }}
                  >
                    {item.name}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </ThemeProvider>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, display: { xs: "block", sm: "none" } }}
          aria-controls={open ? "header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MenuIcon sx={{ width: 32, height: 32, color: "#FFFFFF" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="header-menu"
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {navItems?.map((item, index) => (
            <MenuItem key={index} component={Link} to={item.link}>
              {item.name}
            </MenuItem>
          ))}
        </Menu>
        <Box></Box>
      </Box>
    </>
  );
}

export default Header;
