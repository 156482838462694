import { Box, Typography } from "@mui/material";
import SingleAnimation from "./../animations/SingleAnimation";
import { FormatQuote } from "@mui/icons-material";

export default function HomeHeaderComponent({ open }) {
  const handleOpenModal = (modalName) => {
    open(modalName);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        top: "80%",
        right: 0,
        transform: { xs: "translate(0, 0)", lg: "translate(0, -50%)" },
        gap: "1em",
        zIndex: 2,
      }}
    >
      <SingleAnimation delay={250} right style={{ zIndex: 2 }}>
        <Box
          onClick={() => handleOpenModal("quotation")}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#4200E3",
            p: { xs: "0.5em", lg: "1em 4em" },
            borderTopLeftRadius: "50px",
            borderBottomLeftRadius: "50px",
            cursor: "pointer",
            transition: "transform 0.5s ease-in-out",
            "&:hover": {
              transform: "translate(-50px, 0)",
            },
            textDecoration: "none",
          }}
        >
          <FormatQuote sx={{ color: "#FFF", height: "30px", width: "100%" }} />
          <Typography
            variant="h4"
            sx={{
              fontFamily: "BebasBold",
              color: "background.paper",
              display: { xs: "none", lg: "flex" },
            }}
          >
            GET QUOTATION
          </Typography>
        </Box>
      </SingleAnimation>

      <SingleAnimation delay={500} right style={{ zIndex: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#4200E3",
            p: { xs: "0.5em", lg: "1em 4em" },
            borderTopLeftRadius: "50px",
            borderBottomLeftRadius: "50px",
            cursor: "pointer",
            transition: "transform 0.5s ease-in-out",
            "&:hover": {
              transform: "translate(-50px, 0)",
            },
            textDecoration: "none",
          }}
        >
          <img src="/images/contact.png" style={{ height: "30px" }} />
          <Typography
            variant="h4"
            sx={{
              fontFamily: "BebasBold",
              color: "background.paper",
              display: { xs: "none", lg: "flex" },
            }}
          >
            CONTACT US
          </Typography>
        </Box>
      </SingleAnimation>
    </Box>
  );
}
