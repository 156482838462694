import axios from "axios";
import { useEffect } from "react";

export default function MyAPI({ endpoint, setData }) {
  useEffect(() => {
    const language = window.location.pathname.split("/")[1] || "en";

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/${endpoint}?key=!isDeleted&language=${language}`
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  }, [endpoint, setData]);

  return null;
}
