import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { Facebook, Twitter, Telegram, Instagram } from "@mui/icons-material";

export default function Footer() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const selectedLanguage = useLocation().pathname.split("/")[1];

  const open = Boolean(anchorEl);
  const location = useLocation();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "#FFFFFF",
          },
          scrollButtons: {
            "& .MuiSvgIcon-root": {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
  });

  const navItems = [
    { name: t("home"), link: `/${selectedLanguage}` },
    { name: t("services"), link: `/${selectedLanguage}/services` },
    { name: t("projects"), link: `/${selectedLanguage}/projects` },
    { name: t("addons"), link: `/${selectedLanguage}/addons` },
    { name: t("what’s new"), link: `/${selectedLanguage}/whatisnew` },
  ];

  useEffect(() => {
    const selectedIndex = navItems.findIndex(
      (item) => item.link === location.pathname
    );

    if (selectedIndex !== -1) {
      setValue(selectedIndex);
    }
  }, [location]);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(/images/footerBg.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          p: "1em 2em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "120px",
              height: "60px",
              padding: "1em",
            }}
          >
            <img
              src="/images/shutterLogo.png"
              alt=""
              style={{
                width: "100%",
                zIndex: "1",
              }}
            />
          </Box>
          <ThemeProvider theme={theme}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              sx={{
                "& .MuiTabs-scrollableX .MuiTabs-flexContainer": {
                  gap: { xs: "0.5em", md: "1em", xl: "2em" },
                },
                display: { xs: "none", sm: "flex" },
                direction: selectedLanguage === "en" ? "ltr" : "rtl",
              }}
            >
              {navItems?.map((item, index) => (
                <Tab
                  key={index}
                  component={Link}
                  to={item.link}
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          sm: "20px",
                          md: "24px",
                        },
                        color: "#FFFFFF",
                        textDecoration: "none",
                      }}
                    >
                      {item.name}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
          </ThemeProvider>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, display: { xs: "block", sm: "none" } }}
            aria-controls={open ? "header-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon sx={{ width: 32, height: 32, color: "#FFFFFF" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="header-menu"
            open={open}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {navItems?.map((item, index) => (
              <MenuItem key={index} component={Link} to={item.link}>
                {item.name}
              </MenuItem>
            ))}
          </Menu>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <IconButton
              sx={{
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.3s",
                },
              }}
              onClick={() =>
                window.open("https://www.facebook.com/pentavalue/", "_blank")
              }
            >
              <Facebook sx={{ color: "#FFF", width: "40px", height: "40px" }} />
            </IconButton>

            <IconButton
              sx={{
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.3s",
                },
              }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/pentavalueofficial/",
                  "_blank"
                )
              }
            >
              <Instagram
                sx={{ color: "#FFF", width: "40px", height: "40px" }}
              />
            </IconButton>
          </Box>
        </Box>

        <Divider
          variant="middle"
          component="li"
          sx={{ borderColor: "#FFF", width: "70%", margin: "2em auto" }}
        />

        <Typography
          variant="h6"
          sx={{ color: "#FFF", mt: "2em", textAlign: "center" }}
        >
          All right reserved to PentaValue© 2022
        </Typography>
      </Box>
    </>
  );
}
