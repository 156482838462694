import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/home/index";
import Services from "./pages/services/index";
import SelectedService from "./pages/services/components/selectedService";
import WhatIsNew from "./pages/what's new/index";
import Projects from "./pages/projects/index";
import Addons from "./pages/addons/index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./fonts.css";
import { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SelectedEvent from "./pages/services/components/selectedEvent";
import SelectedActivision from "./pages/services/components/selectedActivision";

function App() {
  let theme = createTheme({
    typography: {
      fontFamily: "CairoRegular",
    },
  });

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const getDefaultLanguage = () => {
    const languageFromPathname =
      window.location.pathname.split("/")[1] || localStorage.getItem("lang");
    localStorage.setItem("lang", languageFromPathname || "en");
    return languageFromPathname || "en";
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Navigate to={`/${getDefaultLanguage()}`} />}
          />
          <Route path=":lang">
            <Route index element={<Home />} />
            <Route path="services" element={<Services />} />
            <Route path="services/:id" element={<SelectedService />} />
            <Route path="events/:id" element={<SelectedEvent />} />
            <Route path="activision/:id" element={<SelectedActivision />} />
            <Route path="projects" element={<Projects />} />
            <Route path="addons" element={<Addons />} />
            <Route path="whatisnew" element={<WhatIsNew />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
