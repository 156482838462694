import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  TextField,
  createTheme,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  IconButton,
  FormHelperText,
  Select,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MyAPI from "./API";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Name must be at least 3 characters")
    .max(25, "Name can't exceed 25 characters")
    .required("Name is required"),
  industry: Yup.string()
    .min(3, "Industry must be at least 3 characters")
    .max(25, "Industry can't exceed 25 characters")
    .required("Industry is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be a 10-digit number")
    .required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .max(100, "Email can't exceed 100 characters")
    .required("Email is required"),
  description: Yup.string()
    .min(3, "Description must be at least 3 characters")
    .max(350, "Description can't exceed 350 characters")
    .required("Description is required"),
  date: Yup.date()
    .min(
      dayjs().toDate(),
      "Date must be later than or equal to the current date"
    )
    .max(
      dayjs().add(1, "year").toDate(),
      "Date must be within one year from now"
    )
    .typeError("Invalid date")
    .required("Required"),
});

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#D9D9D9",
          padding: "0.5em",
          minWidth: "130px",
          borderRadius: "10px",
        },
      },
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function QuotationModal({
  open,
  handleClose,
  onSuccess,
  onError,
}) {
  const [data, setData] = useState([]);
  const [lang, setLang] = useState([]);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const language = window.location.pathname.split("/")[1];
    setLang(language);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      industry: "",
      phone: "",
      email: "",
      description: "",
      services: [],
      date: dayjs(new Date()),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        industry: values.industry,
        phone: values.phone,
        email: values.email,
        description: values.description,
        services,
        date: values.date.toISOString(),
      };

      if (services?.length > 0) {
        if (formik.isValid) {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/quotations`, payload)
            .then((response) => {
              onSuccess();
              handleClose();
            })
            .catch((error) => {
              onError();
              console.error(error);
            });
        } else {
          onError();
        }
      } else setError(true);
    },
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setServices(typeof value === "string" ? value.split(",") : value);
    setError(value?.length === 0);
  };

  return (
    <>
      <MyAPI endpoint={"services"} setData={setData} />

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#0E060E",
              border: "none",
              boxShadow: 24,
              overflow: "auto",
              width: { xs: "100%", sm: "fit-content" },
              height: { xs: "100%", sm: "fit-content" },
              maxHeight: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                p: 4,
              }}
            >
              <img src="/images/confettiBg.png" alt="" />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  [lang === "en" ? "right" : "left"]: 0,
                }}
              >
                <IconButton onClick={handleClose}>
                  <Close
                    sx={{
                      width: "50px",
                      height: "50px",
                      color: "#FFFFFF",
                      transition: "0.5s all",
                      "&:hover": {
                        transform: "scale(1.1)",
                        color: "red",
                      },
                    }}
                  />
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: { xs: "4em", md: "2em" },
                  flexDirection:
                    lang === "en"
                      ? { xs: "column-reverse", md: "row" }
                      : { xs: "column-reverse", md: "row-reverse" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "1em",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#FFFFFF",
                          fontFamily: "Itim",
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                      >
                        {t("Get your Quotation!")}
                      </Typography>

                      <Typography
                        variant="h4"
                        sx={{ color: "#FFFFFF", fontFamily: "Itim" }}
                      >
                        "Your project deserves the best. Get a personalized
                        quote now!"
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="/images/quotation.png"
                        alt=""
                        style={{ maxWidth: "250px" }}
                      />
                    </Box>
                  </Box>

                  <ThemeProvider theme={theme}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1em",
                      }}
                    >
                      <TextField
                        name="name"
                        placeholder={t("Name")}
                        variant="standard"
                        sx={{
                          flex: 1,
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                        InputProps={{ disableUnderline: true }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <TextField
                        name="industry"
                        placeholder={t("Industry")}
                        variant="standard"
                        sx={{
                          flex: 1,
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                        InputProps={{ disableUnderline: true }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.industry}
                        error={
                          formik.touched.industry &&
                          Boolean(formik.errors.industry)
                        }
                        helperText={
                          formik.touched.industry && formik.errors.industry
                        }
                      />
                    </Box>
                    <TextField
                      name="phone"
                      placeholder={t("Phone")}
                      variant="standard"
                      sx={{ direction: lang === "en" ? "ltr" : "rtl" }}
                      InputProps={{ disableUnderline: true }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                    <TextField
                      name="email"
                      placeholder={t("Email")}
                      variant="standard"
                      sx={{ direction: lang === "en" ? "ltr" : "rtl" }}
                      InputProps={{ disableUnderline: true }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </ThemeProvider>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "1em",
                    }}
                  >
                    <FormControl sx={{ width: "100px", flex: 1 }}>
                      <Select
                        name="services"
                        multiple
                        displayEmpty
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        sx={{
                          bgcolor: "#D9D9D9",
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                        value={services}
                        onChange={handleChange}
                        error={error}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <Typography>{t("Select Service(s)")}</Typography>
                            );
                          }

                          return selected.join(", ");
                        }}
                      >
                        <MenuItem disabled value="">
                          <Typography>Select Service(s)</Typography>
                        </MenuItem>
                        {data?.services?.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={
                              item?.translations?.find(
                                (item) => item?.fieldName === "title"
                              )?.translatedValue
                            }
                          >
                            <Checkbox
                              checked={services?.includes(
                                item?.translations?.find(
                                  (item) => item?.fieldName === "title"
                                )?.translatedValue
                              )}
                            />
                            <ListItemText
                              primary={
                                item?.translations?.find(
                                  (translatedItem) =>
                                    translatedItem?.fieldName === "title"
                                )?.translatedValue
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText error>
                          Please select a service
                        </FormHelperText>
                      )}
                    </FormControl>

                    <Box
                      sx={{
                        flex: 1,
                        width: "100%",
                        direction: lang === "en" ? "ltr" : "rtl",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{
                            padding: 0,
                            width: "100%",
                            bgcolor: "#D9D9D9",
                            borderRadius: "4px",
                          }}
                        >
                          <DemoItem>
                            <DatePicker
                              name="date"
                              value={formik.values.date}
                              defaultValue={dayjs(new Date())}
                              minDate={dayjs()}
                              maxDate={dayjs().add(1, "year")}
                              onChange={(date) =>
                                formik.setFieldValue("date", date)
                              }
                              onBlur={formik.handleBlur}
                              sx={{ width: "100%" }}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                      {formik.touched.date && formik.errors.date && (
                        <FormHelperText error>
                          {formik.errors.date}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                  <ThemeProvider theme={theme}>
                    <TextField
                      name="description"
                      multiline
                      rows={2}
                      placeholder={t("Description")}
                      variant="standard"
                      sx={{ direction: lang === "en" ? "ltr" : "rtl" }}
                      InputProps={{ disableUnderline: true }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </ThemeProvider>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#D9AD00",
                      color: "#FFFFFF",
                      width: "fit-content",
                      p: "0.5em 4em",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Itim",
                        direction: lang === "en" ? "ltr" : "rtl",
                      }}
                    >
                      {t("Send")}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
