import {
  Alert,
  Box,
  Button,
  Fade,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import QuotationModal from "../../../components/QuotationModal";
import HomeHeaderComponent from "./../../../components/HeaderBtns";

const staticData = [
  {
    headerBg: "/images/eventH1.png",
    headerTitle: "Explore Communities",
    bodyTitle: "Communities",
    serviceImg: "/images/event1.png",
    serviceTitle: "Communities",
    serviceBody:
      "is a tool designed to simplify event organization. It allows users to easily create an event page with details like title, date, and location. Visitors can register directly through the platform, which integrates with payment systems to streamline revenue management. The platform also provides detailed analytics on participants and overall event performance.",
    previewImg: "/images/preview1.png",
  },

  {
    headerBg: "/images/eventH2.png",
    headerTitle: "Explore Webinar",
    bodyTitle: "Webinar",
    serviceImg: "/images/event2.png",
    serviceTitle: "Webinar",
    serviceBody:
      "Webinars are online sessions that provide educational or presentation content, allowing presenters and the audience to interact in real-time through features like Q&A and discussions.",
    previewImg: "/images/preview2.png",
  },

  {
    headerBg: "/images/eventH3.png",
    headerTitle: "Explore 3D event",
    bodyTitle: "3D event",
    serviceImg: "/images/event3.png",
    serviceTitle: "Roboot",
    serviceBody:
      "Visual Events use visual content like presentations and videos to engage the audience and provide an interactive experience. These events are designed to captivate attendees through dynamic and visually appealing formats, making the content more memorable and impactful.",
    previewImg: "/images/preview3.png",
  },
];

const tabItems = [
  {
    img: "/images/revolt.png",
    title: "Ticket Management",
  },

  {
    img: "/images/camera.png",
    title: "Chat",
  },

  {
    img: "/images/gravity.png",
    title: "Agenda",
  },

  {
    img: "/images/revolt.png",
    title: "Services",
  },

  {
    img: "/images/gif.png",
    title: "Calendar",
  },

  {
    img: "/images/robot.png",
    title: "Timeline",
  },

  {
    img: "/images/infinity.png",
    title: "Event Interaction",
  },

  {
    img: "/images/camera.png",
    title: "Store",
  },

  {
    img: "/images/gravity.png",
    title: "Interactive Sessions",
  },
];

export default function SelectedEvent() {
  const [openModal, setOpenModal] = useState(null);
  const { id } = useParams();

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [snackBar, setSnackBar] = useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarOpen = (Transition, isError) => () => {
    setSnackBar({
      open: true,
      Transition,
      isError,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  return (
    <>
      <Box sx={{ bgcolor: "#0E060E", position: "relative" }}>
        <img src={staticData[id]?.headerBg} style={{ width: "100%" }} />

        <Typography
          variant="h1"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontFamily: "BebasBold",
            color: "#FFF",
          }}
        >
          {staticData[id]?.headerTitle}
        </Typography>

        <HomeHeaderComponent open={handleOpenModal} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "2em",
          bgcolor: "#0E060E",
          position: "relative",
          zIndex: -1,
        }}
      >
        <img
          src="/images/serviceBg1.png"
          alt=""
          style={{
            position: "absolute",
            left: 0,
            height: "300%",
            zIndex: 0,
          }}
        />
        <Typography
          variant="h1"
          sx={{
            fontFamily: "LilyRegular",
            color: "#FFF",
          }}
        >
          {staticData[id]?.bodyTitle}
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: "#0E060E",
          display: "flex",
          maxWidth: "100%",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{ display: "flex", flex: 1, width: "100%", position: "relative" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)",
              zIndex: 1,
            }}
          />

          <img
            src={staticData[id]?.serviceImg}
            alt=""
            style={{
              maxHeight: "60vh",
              maxWidth: "100%",
              width: "100%",
              objectFit: "cover",
              objectPosition: "center",
              zIndex: 0,
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundImage: `url(/images/servicesBg.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            color: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2em",
            gap: "1em",
            flex: 1,
            maxWidth: "100%",
          }}
        >
          <Typography
            variant="h3"
            sx={{ textAlign: "center", fontFamily: "Itim" }}
          >
            {staticData[id]?.serviceTitle}
          </Typography>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontFamily: "Itim" }}
          >
            {staticData[id]?.serviceBody}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ bgcolor: "#0E060E", p: "2em 0" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabItems?.map((item, index) => (
            <Tab
              key={index}
              sx={{ width: "100%", display: "flex" }}
              label={
                <Box>
                  <Box
                    sx={{
                      bgcolor: "#FFFFFF3B",
                      p: "1em",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 auto",
                    }}
                  >
                    <img src={item.img} alt="" style={{ width: "50px" }} />
                  </Box>

                  <Typography
                    variant="body1"
                    sx={{
                      color: "#FFFFFF",
                      textDecoration: "none",
                      mt: "1em",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              }
            />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ bgcolor: "#0E060E", p: "4em" }}>
        <Typography
          variant="h1"
          sx={{
            fontFamily: "LilyRegular",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          Preview
        </Typography>

        <Box sx={{ position: "relative", mt: "2em" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(180deg, rgba(185, 3, 134, 0.4) 0%, rgba(108, 0, 209, 0.4) 100%)",
              zIndex: 1,
              borderRadius: "40px",
            }}
          />

          <img
            src={staticData[id].previewImg}
            alt=""
            style={{ width: "100%", zIndex: 0 }}
          />

          <img
            src="/images/playBtn.png"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
            }}
            alt=""
          />
        </Box>
      </Box>

      <Box sx={{ position: "relative", bgcolor: "#0E060E" }}>
        <img src="/images/metaverse.png" style={{ width: "100%" }} alt="" />

        <Box
          sx={{
            position: "absolute",
            left: "2em",
            bottom: "2em",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ fontFamily: "Itim", color: "#FFF" }}>
            You can now use metaverse easily!
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "Itim",
              color: "#FFF",
              maxWidth: { xs: "100%", lg: "50%" },
            }}
          >
            Empowering Growth Through Shared Learning and Metaverse Services.
            Explore a 3D Virtual Space for Enhanced Communication and
            Interactive Experiences.
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Itim",
              color: "#FFF",
              bgcolor: "#D9AD00",
              mt: "1em",
            }}
          >
            More Details
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: "#0E060E",
          display: "flex",
          maxWidth: "100%",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/experience.png"
            alt=""
            style={{
              zIndex: 0,
            }}
          />
        </Box>

        <Box
          sx={{
            color: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            padding: "2em",
            gap: "1em",
            flex: 1,
            maxWidth: "100%",
          }}
        >
          <Typography variant="h3" sx={{ fontFamily: "Itim" }}>
            Empower your experience for 3 ways
          </Typography>
          <Typography variant="h6" sx={{ fontFamily: "Itim" }}>
            Manage your dashboard easily. Choose Your Design Mode According to
            Your Style. Strategic Business Insights Through Statistics.
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Itim",
              color: "#FFF",
              bgcolor: "#D9AD00",
              mt: "1em",
            }}
          >
            Create Now
          </Button>
        </Box>
      </Box>

      {openModal === "quotation" && (
        <QuotationModal
          open={true}
          handleClose={handleCloseModal}
          onSuccess={handleSnackBarOpen(SlideTransition, false)}
          onError={handleSnackBarOpen(SlideTransition, true)}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        TransitionComponent={snackBar.Transition}
        key={snackBar.Transition.name}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.isError ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {snackBar.isError
            ? "We were unable to submit your form!"
            : "Form Submitted Successfully!"}
        </Alert>
      </Snackbar>
    </>
  );
}
